import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import { useTranslation } from "react-i18next";
import Customers from "components/ui/extended/Customers";
import TestimonialWithImageLaurentiu from "components/ui/extended/TestimonialWithImageLaurentiu";
import ImageHeader from "components/ui/base/ImageHeader";
import { Direction } from "app-types/messenger";

interface DemoIntercomPageProps {
  location: Location;
}

export default ({ location }: DemoIntercomPageProps) => {
  const { t } = useTranslation();

  return (
    <GlobalWrapper
      withLayout
      layoutSettings={{
        withMenu: false,
        withFooter: false,
        clickableLogo: false,
        withCta: false,
        mobileCta: false,
      }}
      location={location}
    >
      <SEO
        title={t("pages.try-customerly.best-intercom-alternative.seo.title")}
        description={t(
          "pages.try-customerly.best-intercom-alternative.seo.description"
        )}
        image={{
          relativePath: "meta/why-customerly-the-best-intercom-alternative.jpg",
          alt: "The Most Competitive Intercom Alternative You Were Hoping To Find Sooner",
        }}
      />

      <ImageHeader
        title={t("pages.lp.intercom.title")}
        description={t("pages.lp.intercom.description")}
        demoButton={{
          show: true,
          path: "/demo",
          text: t("pages.lp.intercom.cta"),
          icon: "arrow-right",
          source: "intercom",
          placeholder: t("pages.lp.intercom.placeholder"),
        }}
        image={{
          position: Direction.Left,
          alt: t("pages.lp.intercom.title"),
          relativePath: "pages/lp/demo/lp-hero.jpg",
        }}
      />

      <Customers withoutMargin />

      <TestimonialWithImageLaurentiu />
    </GlobalWrapper>
  );
};
